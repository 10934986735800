var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeDuplicateSlashes = exports.transform = void 0;
/**
 * Matches a sequence of two or more consecutive slashes, excluding the first two slashes at the beginning of the string.
 * The latter is due to the presence of the device path at the beginning of the UNC path.
 * @todo rewrite to negative lookbehind with the next major release.
 */

const DOUBLE_SLASH_RE = /(?!^)\/{2,}/g;

function transform(patterns) {
  return patterns.map(pattern => removeDuplicateSlashes(pattern));
}

exports.transform = transform;
/**
 * This package only works with forward slashes as a path separator.
 * Because of this, we cannot use the standard `path.normalize` method, because on Windows platform it will use of backslashes.
 */

function removeDuplicateSlashes(pattern) {
  return pattern.replace(DOUBLE_SLASH_RE, "/");
}

exports.removeDuplicateSlashes = removeDuplicateSlashes;
export default exports;