import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _globParent2 from "glob-parent";

var _globParent = "default" in _globParent2 ? _globParent2.default : _globParent2;

import * as _micromatch2 from "micromatch";

var _micromatch = "default" in _micromatch2 ? _micromatch2.default : _micromatch2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchAny = exports.convertPatternsToRe = exports.makeRe = exports.getPatternParts = exports.expandBraceExpansion = exports.expandPatternsWithBraceExpansion = exports.isAffectDepthOfReadingPattern = exports.endsWithSlashGlobStar = exports.hasGlobStar = exports.getBaseDirectory = exports.isPatternRelatedToParentDirectory = exports.getPatternsOutsideCurrentDirectory = exports.getPatternsInsideCurrentDirectory = exports.getPositivePatterns = exports.getNegativePatterns = exports.isPositivePattern = exports.isNegativePattern = exports.convertToNegativePattern = exports.convertToPositivePattern = exports.isDynamicPattern = exports.isStaticPattern = void 0;
const path = _path;
const globParent = _globParent;
const micromatch = _micromatch;
const GLOBSTAR = "**";
const ESCAPE_SYMBOL = "\\";
const COMMON_GLOB_SYMBOLS_RE = /[*?]|^!/;
const REGEX_CHARACTER_CLASS_SYMBOLS_RE = /\[[^[]*]/;
const REGEX_GROUP_SYMBOLS_RE = /(?:^|[^!*+?@])\([^(]*\|[^|]*\)/;
const GLOB_EXTENSION_SYMBOLS_RE = /[!*+?@]\([^(]*\)/;
const BRACE_EXPANSION_SEPARATORS_RE = /,|\.\./;

function isStaticPattern(pattern, options = {}) {
  return !isDynamicPattern(pattern, options);
}

exports.isStaticPattern = isStaticPattern;

function isDynamicPattern(pattern, options = {}) {
  /**
   * A special case with an empty string is necessary for matching patterns that start with a forward slash.
   * An empty string cannot be a dynamic pattern.
   * For example, the pattern `/lib/*` will be spread into parts: '', 'lib', '*'.
   */
  if (pattern === "") {
    return false;
  }
  /**
   * When the `caseSensitiveMatch` option is disabled, all patterns must be marked as dynamic, because we cannot check
   * filepath directly (without read directory).
   */


  if (options.caseSensitiveMatch === false || pattern.includes(ESCAPE_SYMBOL)) {
    return true;
  }

  if (COMMON_GLOB_SYMBOLS_RE.test(pattern) || REGEX_CHARACTER_CLASS_SYMBOLS_RE.test(pattern) || REGEX_GROUP_SYMBOLS_RE.test(pattern)) {
    return true;
  }

  if (options.extglob !== false && GLOB_EXTENSION_SYMBOLS_RE.test(pattern)) {
    return true;
  }

  if (options.braceExpansion !== false && hasBraceExpansion(pattern)) {
    return true;
  }

  return false;
}

exports.isDynamicPattern = isDynamicPattern;

function hasBraceExpansion(pattern) {
  const openingBraceIndex = pattern.indexOf("{");

  if (openingBraceIndex === -1) {
    return false;
  }

  const closingBraceIndex = pattern.indexOf("}", openingBraceIndex + 1);

  if (closingBraceIndex === -1) {
    return false;
  }

  const braceContent = pattern.slice(openingBraceIndex, closingBraceIndex);
  return BRACE_EXPANSION_SEPARATORS_RE.test(braceContent);
}

function convertToPositivePattern(pattern) {
  return isNegativePattern(pattern) ? pattern.slice(1) : pattern;
}

exports.convertToPositivePattern = convertToPositivePattern;

function convertToNegativePattern(pattern) {
  return "!" + pattern;
}

exports.convertToNegativePattern = convertToNegativePattern;

function isNegativePattern(pattern) {
  return pattern.startsWith("!") && pattern[1] !== "(";
}

exports.isNegativePattern = isNegativePattern;

function isPositivePattern(pattern) {
  return !isNegativePattern(pattern);
}

exports.isPositivePattern = isPositivePattern;

function getNegativePatterns(patterns) {
  return patterns.filter(isNegativePattern);
}

exports.getNegativePatterns = getNegativePatterns;

function getPositivePatterns(patterns) {
  return patterns.filter(isPositivePattern);
}

exports.getPositivePatterns = getPositivePatterns;
/**
 * Returns patterns that can be applied inside the current directory.
 *
 * @example
 * // ['./*', '*', 'a/*']
 * getPatternsInsideCurrentDirectory(['./*', '*', 'a/*', '../*', './../*'])
 */

function getPatternsInsideCurrentDirectory(patterns) {
  return patterns.filter(pattern => !isPatternRelatedToParentDirectory(pattern));
}

exports.getPatternsInsideCurrentDirectory = getPatternsInsideCurrentDirectory;
/**
 * Returns patterns to be expanded relative to (outside) the current directory.
 *
 * @example
 * // ['../*', './../*']
 * getPatternsInsideCurrentDirectory(['./*', '*', 'a/*', '../*', './../*'])
 */

function getPatternsOutsideCurrentDirectory(patterns) {
  return patterns.filter(isPatternRelatedToParentDirectory);
}

exports.getPatternsOutsideCurrentDirectory = getPatternsOutsideCurrentDirectory;

function isPatternRelatedToParentDirectory(pattern) {
  return pattern.startsWith("..") || pattern.startsWith("./..");
}

exports.isPatternRelatedToParentDirectory = isPatternRelatedToParentDirectory;

function getBaseDirectory(pattern) {
  return globParent(pattern, {
    flipBackslashes: false
  });
}

exports.getBaseDirectory = getBaseDirectory;

function hasGlobStar(pattern) {
  return pattern.includes(GLOBSTAR);
}

exports.hasGlobStar = hasGlobStar;

function endsWithSlashGlobStar(pattern) {
  return pattern.endsWith("/" + GLOBSTAR);
}

exports.endsWithSlashGlobStar = endsWithSlashGlobStar;

function isAffectDepthOfReadingPattern(pattern) {
  const basename = path.basename(pattern);
  return endsWithSlashGlobStar(pattern) || isStaticPattern(basename);
}

exports.isAffectDepthOfReadingPattern = isAffectDepthOfReadingPattern;

function expandPatternsWithBraceExpansion(patterns) {
  return patterns.reduce((collection, pattern) => {
    return collection.concat(expandBraceExpansion(pattern));
  }, []);
}

exports.expandPatternsWithBraceExpansion = expandPatternsWithBraceExpansion;

function expandBraceExpansion(pattern) {
  return micromatch.braces(pattern, {
    expand: true,
    nodupes: true
  });
}

exports.expandBraceExpansion = expandBraceExpansion;

function getPatternParts(pattern, options) {
  let {
    parts
  } = micromatch.scan(pattern, Object.assign(Object.assign({}, options), {
    parts: true
  }));
  /**
   * The scan method returns an empty array in some cases.
   * See micromatch/picomatch#58 for more details.
   */

  if (parts.length === 0) {
    parts = [pattern];
  }
  /**
   * The scan method does not return an empty part for the pattern with a forward slash.
   * This is another part of micromatch/picomatch#58.
   */


  if (parts[0].startsWith("/")) {
    parts[0] = parts[0].slice(1);
    parts.unshift("");
  }

  return parts;
}

exports.getPatternParts = getPatternParts;

function makeRe(pattern, options) {
  return micromatch.makeRe(pattern, options);
}

exports.makeRe = makeRe;

function convertPatternsToRe(patterns, options) {
  return patterns.map(pattern => makeRe(pattern, options));
}

exports.convertPatternsToRe = convertPatternsToRe;

function matchAny(entry, patternsRe) {
  return patternsRe.some(patternRe => patternRe.test(entry));
}

exports.matchAny = matchAny;
export default exports;