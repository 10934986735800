import _utils from "../../utils";
import _partial from "../matchers/partial";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;
const partial_1 = _partial;

class DeepFilter {
  constructor(_settings, _micromatchOptions) {
    this._settings = _settings;
    this._micromatchOptions = _micromatchOptions;
  }

  getFilter(basePath, positive, negative) {
    const matcher = this._getMatcher(positive);

    const negativeRe = this._getNegativePatternsRe(negative);

    return entry => this._filter(basePath, entry, matcher, negativeRe);
  }

  _getMatcher(patterns) {
    return new partial_1.default(patterns, this._settings, this._micromatchOptions);
  }

  _getNegativePatternsRe(patterns) {
    const affectDepthOfReadingPatterns = patterns.filter(utils.pattern.isAffectDepthOfReadingPattern);
    return utils.pattern.convertPatternsToRe(affectDepthOfReadingPatterns, this._micromatchOptions);
  }

  _filter(basePath, entry, matcher, negativeRe) {
    if (this._isSkippedByDeep(basePath, entry.path)) {
      return false;
    }

    if (this._isSkippedSymbolicLink(entry)) {
      return false;
    }

    const filepath = utils.path.removeLeadingDotSegment(entry.path);

    if (this._isSkippedByPositivePatterns(filepath, matcher)) {
      return false;
    }

    return this._isSkippedByNegativePatterns(filepath, negativeRe);
  }

  _isSkippedByDeep(basePath, entryPath) {
    /**
     * Avoid unnecessary depth calculations when it doesn't matter.
     */
    if (this._settings.deep === Infinity) {
      return false;
    }

    return this._getEntryLevel(basePath, entryPath) >= this._settings.deep;
  }

  _getEntryLevel(basePath, entryPath) {
    const entryPathDepth = entryPath.split("/").length;

    if (basePath === "") {
      return entryPathDepth;
    }

    const basePathDepth = basePath.split("/").length;
    return entryPathDepth - basePathDepth;
  }

  _isSkippedSymbolicLink(entry) {
    return !this._settings.followSymbolicLinks && entry.dirent.isSymbolicLink();
  }

  _isSkippedByPositivePatterns(entryPath, matcher) {
    return !this._settings.baseNameMatch && !matcher.match(entryPath);
  }

  _isSkippedByNegativePatterns(entryPath, patternsRe) {
    return !utils.pattern.matchAny(entryPath, patternsRe);
  }

}

exports.default = DeepFilter;
export default exports;